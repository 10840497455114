import React from "react";

import { 
    GoogleMap, 
    InfoWindow, 
    Marker, 
    Polygon, 
    useJsApiLoader,
    MarkerClusterer
} from '@react-google-maps/api';
import { PageContext } from "./PageContext";
import axios from "axios";
import ImageGallery from 'react-image-gallery';
import HouseMarker from 'assets/img/house.svg';
import LandMarker from 'assets/img/land.svg';
import CommercialMarker from 'assets/img/commercial.svg';
import HotelMarker from 'assets/img/hotel.svg';
import FactoryMarker from 'assets/img/factory.svg';
import MoreMarker from 'assets/img/more.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faChevronLeft, 
    faChevronRight,
    faList,
    faMapLocationDot,
    faLocationDot,
    faShareNodes,
    faLink,
    faPenRuler,
    faEraser
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    Row,
    Col,
    Card,
    CardImg,
    CardBody,
    CardText,
    CardTitle,
    NavItem,
    Nav,
    NavLink,
    PopoverBody,
    UncontrolledPopover
} from 'reactstrap';
import {
    FacebookShareButton,
    LineShareButton,
    TwitterShareButton
} from 'react-share';
import {
    faFacebookF,
    faLine,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import Pagination from "components/pagination/Pagination";
import { useLocation } from 'react-router-dom';
import clustererImg from 'assets/img/Geo-1.svg';
import clustererImg1 from 'assets/img/Geo-1 or.svg';
import clustererImg2 from 'assets/img/Geo-1 red.svg';
import MeasureTool from 'measuretool-googlemaps-v3';

const google_api = process.env.REACT_APP_GOOGLE_API;
const path_url = process.env.REACT_APP_API_CONNECT;
// const domain = `${window.location.origin}${window.location.pathname}${window.location.search}`
// const share_url = domain;
const name2 = `${window.location.origin}`

function MyMaps(){
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: google_api,
        region:'th',
        language: 'th',
        version: "weekly",
        libraries: ['geometry']
    });
    const { pageData, color } = React.useContext(PageContext);
    const [toggleList, setToggleList] = React.useState(false);
    const [coordinateCenter] = React.useState({
        lat: 13.041695,
        lng: 100.3771591,
    })
    const [fetchSaleData, setFethSaleData] = React.useState([]);
    const [EststeActive, setEstateActive] = React.useState(null);
    // const { userData, setUserData } = React.useContext(LoginContext);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize] = React.useState(12);
    // const history = useHistory();
    const [filter, setFilter] = React.useState('ALL');
    const [map, setMap] = React.useState(null);

    const google = window.google;
    

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    
    let query = useQuery();

    React.useEffect(() => {
        _onFetchMap()

        const tab = query.get('t');
        const filterer = query.get('category');
        if(tab && tab === "list"){
            setToggleList(true)
        }
    
        if(filterer){
            setFilter(filterer)
        }
    },[])
    

    async function _onFetchMap(){
        try{
            const result = await axios.post(path_url+'/landingpage/getEstateFromMaps',{
                user: pageData?.page?.user?._id
            })

            if(result.status === 200){
                setFethSaleData(result.data)
                // console.log(result.data)
            }
        }catch(e){
            console.log(e)
        }
    }

    const handleActiveMarker = (val) =>{
        setEstateActive(val)
        // console.log(val)
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const parePrice = (val) =>{
        let thaibath = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return thaibath;
    }

    const toEstateDetail = (val) =>{
        window.open(`/detail/?sale=${val}`)
        // router.push(`/EstateDetail/?sale=${val}`)
    }

    const fetchFilter = fetchSaleData?.filter((val) => {
        if(filter === "ALL"){
            return val
        }else{
            return val.saleCategory === filter
        }
    })

    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    const dataFetch = fetchFilter?.slice(firstPageIndex, lastPageIndex);

    const copylink = async (val) =>{
        try{
            await navigator.clipboard.writeText(`${name2}/detail/?sale=${val}`)
        }catch(e){
            console.log(e)
        }
    }
    
    var measure;
    function measureToolClick(){
        measure = new MeasureTool(map,{
            contextMenu: false,
            unit: MeasureTool.UnitTypeId.METRIC,
        })

        measure.addListener('measure_start', () => {
            console.log('started');
        });

        measure.start()
    }

    function measureToolEnd(){
        measure.end()
        // console.log(fetchSaleData)
    }

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    return(pageData ?
        <>
        {!toggleList ? 
        <div className="mymap-container">
            <div className="filter-container">
                <Nav>
                    <NavItem 
                    active={filter === "ALL" ? true : false }
                    onClick={() => setFilter("ALL")}
                    >
                        <NavLink active={filter === "ALL" ? true : false } >
                            ทั้งหมด
                        </NavLink>
                    </NavItem>
                    <div className="vr1" />
                    <NavItem 
                    active={filter === "LAND" ? true : false }
                    onClick={() => setFilter("LAND")}
                    >
                        <NavLink active={filter === "LAND" ? true : false }>
                            ที่ดิน
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "HOME" ? true : false }
                    onClick={() => setFilter("HOME")}
                    >
                        <NavLink active={filter === "HOME" ? true : false }>
                            บ้าน
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "COMMERCIAL" ? true : false }
                    onClick={() => setFilter("COMMERCIAL")}
                    >
                        <NavLink active={filter === "COMMERCIAL" ? true : false }>
                            อาคารพาณิชย์
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "HOTEL" ? true : false }
                    onClick={() => setFilter("HOTEL")}
                    >
                        <NavLink active={filter === "HOTEL" ? true : false }>
                            โรงแรม/รีสอร์ท
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "FACTORY" ? true : false }
                    onClick={() => setFilter("FACTORY")}
                    >
                        <NavLink active={filter === "FACTORY" ? true : false }>
                            โรงงาน/โกดัง
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "MORE" ? true : false }
                    onClick={() => setFilter("MORE")}
                    >
                        <NavLink active={filter === "MORE" ? true : false }>
                            อื่นๆ
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
            {isLoaded ?
            <GoogleMap
            mapContainerClassName="mapWrapper"
            id="map"
            center={coordinateCenter}
            zoom={6}
            options={{ 
                gestureHandling: 'greedy', 
                fullscreenControl: false,
                mapTypeControl: true,
                mapTypeControlOptions:{
                    position: google && google.maps.ControlPosition.LEFT_TOP,
                    style: google && google.maps.MapTypeControlStyle.DROPDOWN_MENU
                },
                // mapId:"cec75db59da49d87"
            }}
            onLoad={onLoad}
            >
                <div
                style={{
                    display:'flex', 
                    position:'absolute', 
                    left: '45%',
                    top: 10,
                    justifyContent:'center',
                    alignItems:'center'
                }}
                >
                    <button
                    style={{
                        marginLeft: 7,
                        marginRight: 7,
                        width: 35, 
                        height: 35, 
                        borderRadius: 25, 
                        border: 'none', 
                        backgroundColor: '#fff',
                        color: color,
                        cursor: 'pointer'
                    }}
                    onClick={() => measureToolClick()}
                    >
                        <FontAwesomeIcon icon={faPenRuler} />
                    </button>
                    <button
                    style={{
                        marginLeft: 7,
                        marginRight: 7,
                        width: 35, 
                        height: 35, 
                        borderRadius: 25, 
                        border: 'none', 
                        backgroundColor: '#fff',
                        color: color,
                        cursor: 'pointer'
                    }}
                    onClick={() => measureToolEnd()}
                    >
                        <FontAwesomeIcon icon={faEraser} />
                    </button>
                </div>

                <MarkerClusterer
                enableRetinaIcons
                gridSize={60}
                options={{
                    styles: [
                        {
                            textColor: 'white',
                            url: clustererImg,
                            width: 50,
                            height: 50
                        },
                        {
                            textColor: 'white',
                            url: clustererImg1,
                            width: 50,
                            height: 50
                        },
                        {
                            textColor: 'white',
                            url: clustererImg2,
                            width: 50,
                            height: 50
                        }
                    ]
                }}
                >
                {(clusterer) => (
                    <>
                    {fetchFilter?.map((items, i) =>{
                        return(
                        <div key={i}>
                            <Polygon 
                            options={{
                                strokeColor: items?.saleCategory === 'LAND' ? '#EF9751'   
                                : items?.saleCategory === 'HOME' ? '#1789FE'
                                : items?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                : items?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                : items?.saleCategory === 'FACTORY' ? '#884ea0'
                                : '#595959' ,
                                strokeWeight: 3,
                                fillColor: items?.saleCategory === 'LAND' ? '#EF9751'   
                                : items?.saleCategory === 'HOME' ? '#1789FE'
                                : items?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                : items?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                : items?.saleCategory === 'FACTORY' ? '#884ea0'
                                :'#595959',
                                strokeOpacity: 1
                            }}
                            path={items?.titleDeed?.paths?.coordinates[0].map(element =>{
                                return{lat: element[1], lng: element[0]}
                            })}
                            // onClick={() => handleActiveMarker(items)}
                            />
                            {items?.titleDeed ? (
                            <Marker
                            clusterer={clusterer}
                            icon={{
                                url: items?.saleCategory === 'LAND' ? LandMarker 
                                :items?.saleCategory === 'HOME' ? HouseMarker
                                :items?.saleCategory === 'COMMERCIAL' ? CommercialMarker
                                :items?.saleCategory === 'HOTEL' ? HotelMarker
                                :items?.saleCategory === 'FACTORY' ? FactoryMarker
                                :MoreMarker,
                                scaledSize: items?.saleCategory === 'LAND' ? new google.maps.Size(150,49,'px','px') 
                                :items?.saleCategory === 'HOME' ? new google.maps.Size(160,49,'px','px')
                                :items?.saleCategory === 'COMMERCIAL' ? new google.maps.Size(155,49,'px','px')
                                :items?.saleCategory === 'HOTEL' ? new google.maps.Size(160,49,'px','px')
                                :items?.saleCategory === 'FACTORY' ? new google.maps.Size(160,49,'px','px')
                                :new google.maps.Size(150,49,'px','px'),
                            }}
                            label={{
                                color: items?.saleCategory === 'LAND' ? '#EF9751'   
                                :items?.saleCategory === 'HOME' ? '#1789FE'
                                :items?.saleCategory === 'COMMERCIAL' ? '#2EB82E'
                                :items?.saleCategory === 'HOTEL' ? '#FF4D4D'
                                :items?.saleCategory === 'FACTORY' ? '#884ea0'
                                :'#595959',
                                text: items?.saleCategory === 'LAND' 
                                ? `${intl.format(items?.pricePerWa)}` 
                                : `${intl.format(items?.totalPrice)}`,
                                fontFamily: "'Prompt', sans-serif"
                            }}
                            position={{
                                lat: items?.titleDeed?.centerPoint?.coordinates[1],
                                lng: items?.titleDeed?.centerPoint?.coordinates[0]
                            }}
                            onClick={() => handleActiveMarker(items)}
                            >
                                {EststeActive?._id === items?._id ? 
                                <InfoWindow 
                                onCloseClick={() => setEstateActive(null)}
                                >
                                    <>
                                    <ImageGallery 
                                    showPlayButton={false}
                                    showThumbnails={false}
                                    showFullscreenButton={false}
                                    renderLeftNav={(onClick, disable) =>(
                                        <Button 
                                        type="button"
                                        className="image-gallery-icon image-gallery-left-nav" 
                                        disabled={disable}
                                        onClick={onClick}
                                        aria-label="Previous Slide"
                                        >
                                            <FontAwesomeIcon icon={faChevronLeft} onClick={onclick} className="gallery-icon-size" />
                                        </Button>
                                    )}
                                    renderRightNav={(onClick, disable) =>(
                                        <Button 
                                        type="button"
                                        className="image-gallery-icon image-gallery-right-nav" 
                                        disabled={disable}
                                        onClick={onClick}
                                        aria-label="Next Slide"
                                        >
                                            <FontAwesomeIcon icon={faChevronRight} onClick={onclick} className="gallery-icon-size" />
                                        </Button>
                                    )}
                                    items={EststeActive?.images.map((images) =>{
                                        return{
                                            original : images.path,
                                            originalClass:"item-img",
                                        }
                                    })}
                                    />
                                    <div className="mt-20 mb-10" style={{borderBottom:'0.5px solid gray'}}>
                                        <p 
                                        className="font-size-sm font-weight-500 btn" 
                                        onClick={() => toEstateDetail(items?._id)}
                                        >
                                            {EststeActive?.title}
                                        </p>
                                        {/* <p className="font-size-sm mt-10 mb-10">{EststeActive?.owner?.description}</p> */}
                                    </div>
                                    <Row>
                                        <Col className="mb-10">
                                            <p className="font-size-sm color-gray">ขนาดแปลงที่ดิน</p>
                                            <p className="font-size-sm" style={{color: color ? color : '#1789FE'}}>
                                                {EststeActive?.size.rai + " ไร่ " + EststeActive?.size.ngan + " งาน " + EststeActive?.size.wa + " วา"}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="row-cols-2 pb-10 mt-20" style={{borderBottom:'0.5px solid gray'}}>
                                        <Col>
                                            <p className="font-size-sm color-gray">ราคารวม</p>
                                            <p className="font-weight-500" style={{color: color ? color : '#1789FE'}}>
                                                {parePrice(EststeActive?.totalPrice)} บาท
                                            </p>
                                        </Col>
                                        {EststeActive?.saleCategory === 'LAND'  ? 
                                        <Col>
                                            <p className="font-size-sm color-gray">ราคาต่อตารางวา</p>
                                            <p className="font-weight-500" style={{color: color ? color : '#1789FE'}}>
                                                {parePrice(EststeActive?.pricePerWa)} บาท
                                            </p>
                                        </Col> : null}
                                    </Row>
                                    <Row>
                                        <Col className="col-12" style={{display:'flex', justifyContent:'center'}}>
                                            <Button 
                                            type="button" 
                                            color="transparent" 
                                            className="pb-10 pt-10"
                                            style={{color: color ? color : '#1789FE'}}
                                            onClick={() => toEstateDetail(EststeActive?._id)}
                                            >
                                                ดูรายละเอียด
                                            </Button>
                                        </Col>
                                    </Row>
                                    </>
                                </InfoWindow>
                                :null}
                            </Marker> )
                            : null}
                        </div>
                        )
                    })}
                    </>
                )}
                </MarkerClusterer>
            </GoogleMap>
            :null}
            <div className="buttom-container">
                <button 
                className="btn-buttom" 
                style={{backgroundColor: color ? color : '#1789FE'}}
                onClick={() => setToggleList(!toggleList)}
                >
                    <FontAwesomeIcon icon={faList} className="mr-10" />
                    แสดงรายการ
                </button>
                
            </div>
        </div>
        : 
        <div className="mylist-container">
            <div className="filter-container">
                <Nav>
                    <NavItem 
                    active={filter === "ALL" ? true : false }
                    onClick={() => setFilter("ALL")}
                    >
                        <NavLink active={filter === "ALL" ? true : false } >
                            ทั้งหมด
                        </NavLink>
                    </NavItem>
                    <div className="vr1" />
                    <NavItem 
                    active={filter === "LAND" ? true : false }
                    onClick={() => setFilter("LAND")}
                    >
                        <NavLink active={filter === "LAND" ? true : false }>
                            ที่ดิน
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "HOME" ? true : false }
                    onClick={() => setFilter("HOME")}
                    >
                        <NavLink active={filter === "HOME" ? true : false }>
                            บ้าน
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "COMMERCIAL" ? true : false }
                    onClick={() => setFilter("COMMERCIAL")}
                    >
                        <NavLink active={filter === "COMMERCIAL" ? true : false }>
                            อาคารพาณิชย์
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "HOTEL" ? true : false }
                    onClick={() => setFilter("HOTEL")}
                    >
                        <NavLink active={filter === "HOTEL" ? true : false }>
                            โรงแรม/รีสอร์ท
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "FACTORY" ? true : false }
                    onClick={() => setFilter("FACTORY")}
                    >
                        <NavLink active={filter === "FACTORY" ? true : false }>
                            โรงงาน/โกดัง
                        </NavLink>
                    </NavItem>
                    <NavItem 
                    active={filter === "MORE" ? true : false }
                    onClick={() => setFilter("MORE")}
                    >
                        <NavLink active={filter === "MORE" ? true : false }>
                            อื่นๆ
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
                {dataFetch.map((item, i) =>{
                    return(
                        <Col key={i} style={{marginBottom: 20}}>
                            <Card style={{cursor: 'pointer'}}>
                                <div className="card-tag">
                                    <div className="tag">
                                        {item?.quick ? 
                                        <div className="tag-2 bg_redpink color-white mb-10">
                                            ขายด่วน
                                        </div> 
                                        : null}
                                    </div>
                                    <Button 
                                    id={`popover${i}`}
                                    type="button"
                                    color="share"
                                    // onClick={() => setPopoverShare(true)}
                                    >
                                        <FontAwesomeIcon icon={faShareNodes} style={{fontSize: 20}} />
                                    </Button>
                                    <UncontrolledPopover 
                                    target={`popover${i}`}
                                    placement="bottom" 
                                    id={`popover${i}`}
                                    >
                                        <PopoverBody>
                                            <FacebookShareButton 
                                            url={`${name2}/detail/?sale=${item._id}`} 
                                            className="btn-share-facebook mr-10"
                                            >
                                                <FontAwesomeIcon icon={faFacebookF} style={{color:'white'}} />
                                            </FacebookShareButton>
                                            <LineShareButton
                                            url={`${name2}/detail/?sale=${item._id}`}
                                            className="btn-share-line mr-10"
                                            >
                                                <FontAwesomeIcon icon={faLine} style={{color:'white'}} />
                                            </LineShareButton>
                                            <TwitterShareButton
                                            url={`${name2}/detail/?sale=${item._id}`}
                                            className="btn-share-twitter mr-10"
                                            >
                                                <FontAwesomeIcon icon={faTwitter} style={{color:'white'}} />
                                            </TwitterShareButton>
                                            <Button
                                            color="share-copylink"
                                            onClick={() => copylink(item._id)}
                                            id={`tooltip${i}`}
                                            >
                                                <FontAwesomeIcon icon={faLink} />
                                            </Button>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                    <CardImg 
                                    alt={item?.titleDeedNumber}
                                    src={item?.images[0]?.path} 
                                    onClick={() => toEstateDetail(item?._id)}
                                    />
                                </div>
                                <CardBody onClick={() => toEstateDetail(item?._id)}>
                                    <div className="body-1">
                                        <CardTitle>
                                            {item?.title}
                                        </CardTitle>
                                        <CardText>{item?.description}</CardText>
                                    </div>
                                    <div className="body-2">
                                        <CardText>{intl.format(item?.totalPrice)}</CardText>
                                        {item?.saleCategory === 'LAND' ?
                                            <CardText style={{color: color ? color : '#1789FE'}} >{intl.format(item?.pricePerWa)}/ตร.ว.</CardText>
                                        :null}
                                    </div>
                                    <CardText>
                                        <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                        {item?.saleDistrict + ", " + item?.saleProvince}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
            <div style={{display: 'flex', justifyContent:'flex-end'}}>
                <Pagination
                className="pagination-bar pagination-bar-custom"
                currentPage={currentPage}
                totalCount={fetchFilter.length}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
                />
            </div>
            <div className="buttom-container">
                <button 
                className="btn-buttom" 
                style={{backgroundColor: color ? color : '#1789FE'}}
                onClick={() => setToggleList(!toggleList)}
                >
                    <FontAwesomeIcon icon={faMapLocationDot} className="mr-10" />
                    แสดงแผนที่
                </button>
            </div>
        </div>
        }
        </>
    : null)
}
export default MyMaps;