import React from "react";
import loading from 'assets/img/Magnify-1.4s-200px.svg'
function LoadingIndicretor2(props) {
    const { className, visible, alt } = props
    return(
        visible ?
            <img 
            src={loading} 
            className={className} 
            alt={alt}
            width={80} height={50} 
            />
        :null
    )
}

export default LoadingIndicretor2;