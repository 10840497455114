import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF, 
    faLine, 
    faYoutube,
    faTiktok
} from "@fortawesome/free-brands-svg-icons";
import { PageContext } from "views/PageContext";

// const line_path = process.env.REACT_APP_LINE_PATH;

function FooterCustom(){
    const { pageData} = React.useContext(PageContext);

    return(
        <>
        <div className="footer">
            <div className="footer-section-1">
                <h4>{pageData?.page?.titleName}</h4>
                <p>ติดต่อ : {pageData?.page?.user?.phoneNumber}</p>
                <p>ที่อยู่ : {pageData?.page?.address}</p>
            </div>
            <div className="footer-section-2">
                <div className="footer-section-2-social">
                    {pageData?.page?.social?.facebookUrl ? 
                        <button className="btn-social mr-10" onClick={() => window.open(pageData?.page?.social?.facebookUrl)}>
                            <FontAwesomeIcon icon={faFacebookF} className="icon" />
                        </button>
                    : null}
                    {pageData?.page?.social?.lineUrl ? 
                        <button className="btn-social mr-10" onClick={() => window.open(pageData?.page?.social?.lineUrl)}>
                            <FontAwesomeIcon icon={faLine} className="icon" />
                        </button>
                    : null}
                    {pageData?.page?.social?.tiktokUrl ? 
                        <button className="btn-social mr-10" onClick={() => window.open(pageData?.page?.social?.tiktokUrl)}>
                            <FontAwesomeIcon icon={faTiktok} className="icon" />
                        </button>
                    : null}
                    {pageData?.page?.social?.youtubeUrl ? 
                        <button className="btn-social mr-10" onClick={() => window.open(pageData?.page?.social?.youtubeUrl)}>
                            <FontAwesomeIcon icon={faYoutube} className="icon" />
                        </button>
                    : null}
                </div>
                <div>
                    <a href="https://www.taladteedin.com/Privacy" target="_blank" className="foot-sec-text">นโยบาย</a>
                    © {new Date().getFullYear()}, Developed By TaladTeedin.com Co., Ltd.
                </div>
            </div>
        </div>
        </>
    )
}
export default FooterCustom