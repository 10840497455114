import React from "react";
import axios from "axios";

const path_url = process.env.REACT_APP_API_CONNECT;

export const LoginContext = React.createContext();

export function LoginProvider(props){
    const [userData, setUserData] = React.useState(null);

    function LoginSuccess(data){
        localStorage.setItem('profile', JSON.stringify({uid: data._id}))
        setUserData(data) 
    }

    async function LoginSession(){
        const sessionId = localStorage.getItem('profile')
        const pare = JSON.parse(sessionId);
        
        if(pare){
            axios.post(path_url+'/user/loginSession',{
                userID: pare.uid || pare._id
            })
            .then(res =>{
                const data = res.data;
                if(data.role === "PREMIUM"){
                    const datenow = new Date().getTime()
                    const datepremium = new Date(data.expiredAt).getTime()
                    // console.log(datenow, datepremium)
                    if(datenow >= datepremium){
                        data["role"] = "USER"
                    }
                }
                LoginSuccess(data)
            })
            .catch(e =>{
                console.log(e)
            })
        }
    }

    return(
        <LoginContext.Provider
        value={{
            userData,
            setUserData,
            LoginSuccess,
            LoginSession
        }}
        >
            {props.children}
        </LoginContext.Provider>
    )
}