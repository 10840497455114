import React from "react";
import { 
  BrowserRouter, 
  Switch, 
  Route 
} from "react-router-dom";

import PageRouter from "views/PageRouter";
import ErrorPage from "views/error404";
// import { PageProvider } from "views/PageContext";
import axios from "axios";

import 'assets/scss/styles.scss';
import 'assets/css/bootstrap-grid.css';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-multi-carousel/lib/styles.css";
import 'react-photoswipe/lib/photoswipe.css';

function App() {

  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  
  return (
    <BrowserRouter >
      <Switch>
        {/* <PageProvider> */}
          <Route path="/" render={(props) => <PageRouter {...props} />} />
        {/* </PageProvider> */}
        <Route path="*" render={(props) => <ErrorPage {...props} />} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
