import React from "react";
import { 
    Badge, 
    Button, 
    Card,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
    CardFooter,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faLocationDot, 
    faHouse,
    faBuilding,
    faHotel,
    faIndustry,
    faCircleInfo,
    faThumbTack,
    faBullhorn,
    faMapLocationDot,
    faCity,
    faPhone,
    faAnglesRight,
    faEllipsisV,
    faFileImport
} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebookF,
    faLine,
    faTiktok,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
    faCommentDots
} from "@fortawesome/free-regular-svg-icons"
import Carousel from "react-multi-carousel";
import blank_img from 'assets/img/blank-profile.png';
import { TikTok } from "react-tiktok";
import mappic from 'assets/img/taladteedinpic.png';
import { PageContext } from "./PageContext";
import { Link, useHistory } from 'react-router-dom'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
};

const banner = "https://taladteedin.sgp1.digitaloceanspaces.com/landmarket-prod/banner/anthonyb5POxb2aL9o.jpeg"

function MyPage(){
    const { pageData, color } = React.useContext(PageContext);
    // let { name } = useParams();
    const history = useHistory();
    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const toEstateDetail = (val) =>{
        window.open(`/detail/?sale=${val}`)
        // window.location.href = `/EstateDetail/?sale=${val}`
    }

    React.useEffect(() => {
        var textfirst = document.getElementById('text-telling')
        setTimeout(function(){
            textfirst.classList.add('block')
        },5000)
    },[])

    // console.log(pageData)

    return(
        pageData ? 
            <>
            <div className="mypage-container">
                <div className="page-banner">
                    <img 
                    alt="" 
                    src={pageData?.page?.bannerImg ? pageData?.page?.bannerImg : banner} 
                    />
                    {/* <span className="text-title">
                        <h1 className="title">{pageData?.page?.titleName ? pageData?.page?.titleName : ""}</h1>
                    </span> */}
                    <div className="property-box">
                        <div className="property-wrapper">
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: color,
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative'
                            }}
                            onClick={() => window.open(`/maps?t=list&&category=ALL`)}
                            >
                                ทั้งหมด
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    {
                                        pageData?.countLand + 
                                        pageData?.countHome + 
                                        pageData?.countCommercial + 
                                        pageData?.countHotel + 
                                        pageData?.countFactory + 
                                        pageData?.countMore
                                    }
                                </Badge>
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            onClick={() => history.push(`/maps?t=list&&category=LAND`)}
                            >
                                <FontAwesomeIcon icon={faLocationDot} className="mr-10" />
                                ที่ดิน
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    {pageData?.countLand}
                                </Badge>
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            onClick={() => history.push(`/maps?t=list&&category=HOME`)}
                            >
                                <FontAwesomeIcon icon={faHouse} className="mr-10" />
                                บ้าน
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    {pageData?.countHome}
                                </Badge>
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            onClick={() => history.push(`/maps?t=list&category=COMMERCIAL`)}
                            >
                                <FontAwesomeIcon icon={faBuilding} className="mr-10" />
                                อาคารพาณิชย์
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    {pageData?.countCommercial}
                                </Badge>
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            onClick={() => history.push(`/maps?t=list&&category=HOTEL`)}
                            >
                                <FontAwesomeIcon icon={faHotel} className="mr-10" />
                                โรงแรม/รีสอร์ท
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    {pageData?.countHotel}
                                </Badge>
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            onClick={() => history.push(`/maps?t=list&&category=FACTORY`)}
                            >
                                <FontAwesomeIcon icon={faIndustry} className="mr-10" />
                                โรงงาน/โกดัง
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    {pageData?.countFactory}
                                </Badge>
                            </Button>
                            <Button 
                            color="default"
                            style={{
                                backgroundColor: '#fff',
                                marginLeft: 10,
                                marginRight: 10,
                                position:'relative',
                                color: 'black'
                            }}
                            onClick={() => history.push(`/maps?t=list&&category=MORE`)}
                            >
                                <FontAwesomeIcon icon={faCircleInfo} className="mr-10" />
                                อื่นๆ
                                <Badge 
                                pill
                                style={{
                                    border: '2px solid white',
                                    position:'absolute',
                                    top: -10,
                                    right: -15,
                                    backgroundColor: color
                                }}
                                >
                                    {pageData?.countMore}
                                </Badge>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="page-maps">
                    <img src={mappic} className="map_pic" />
                    <div className="container" />
                    <div className="text-container">
                        <h3>ค้นหาบนแผนที่</h3>
                        {/* <div className="mb-30">
                            <p>ตรวจสอบที่ดินทั้งหมดบนแผนที่ ซึ่งเป็นแผนที่เฉพาะทรัพย์ของนายหน้า</p>
                        </div> */}
                        <Link
                        className="btn btn-default"
                        style={{
                            backgroundColor: color,
                            color: 'white'
                        }}
                        to={`/maps`}
                        // onClick={() => console.log(pageName)}
                        >
                            <FontAwesomeIcon icon={faMapLocationDot} className="mr-10" />
                            ไปยังแผนที่
                        </Link>
                    </div>
                </div>
                {pageData?.page?.pin?.length > 0 ? 
                <div className="page-body-1">
                    <p className="title mb-10">
                        <FontAwesomeIcon icon={faThumbTack} className="mr-20 icon" />
                        ทรัพย์แนะนำ
                    </p>
                    <Carousel 
                    responsive={responsive}
                    infinite={false}
                    autoPlay={false}
                    ssr={true}
                    shouldResetAutoplay={false}
                    containerClass="carousel-container mb-30"
                    itemClass="carousel-item"
                    >
                        {pageData?.page?.pin?.map((item, i) =>{
                            return(
                                <Card 
                                style={{cursor:'pointer'}} 
                                key={i}
                                onClick={() => toEstateDetail(item?._id)}
                                >
                                    <div className="card-tag">
                                        <div className="tag">
                                            {/* {item?.saleType === "OWNER" ?
                                            <div className="tag-1 bg_default color-white mt-10">
                                                เจ้าของขายเอง
                                            </div>
                                            :
                                            null} */}
                                            {item.quick ? 
                                            <div className="tag-2 bg_redpink color-white mt-10">
                                                ขายด่วน
                                            </div>
                                            : null }
                                        </div>
                                        <CardImg 
                                        alt=""
                                        src={item?.images[0]?.path} 
                                        />
                                    </div>
                                    <CardBody>
                                        <div className="body-1">
                                            <CardTitle>
                                                {item?.title}
                                            </CardTitle>
                                            <CardText>
                                                {item?.description}
                                            </CardText>
                                        </div>
                                        <div className="body-2">
                                            <CardText>{intl.format(item?.totalPrice)}</CardText>
                                            {item?.pricePerWa ?
                                                <CardText style={{color: color}} className="color-default">{intl.format(item?.pricePerWa)}/ตร.ว.</CardText>
                                            :null}
                                        </div>
                                        <CardText>
                                            <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                            {item?.saleDistrict + ", " + item?.saleProvince}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            )
                        })}
                    </Carousel>
                </div>
                : null}
                <div className="page-body-2">
                    <p className="title mb-10">
                        รายการประกาศทั้งหมด
                    </p>
                    <Carousel 
                    responsive={responsive}
                    infinite={false}
                    autoPlay={false}
                    ssr={true}
                    shouldResetAutoplay={false}
                    containerClass="carousel-container mb-30"
                    itemClass="carousel-item"
                    >
                        {pageData?.sale?.map((item, i) =>{
                            return(
                                <Card 
                                style={{cursor:'pointer'}} 
                                key={i}
                                onClick={() => toEstateDetail(item?._id)}
                                >
                                    <div className="card-tag">
                                        <div className="tag">
                                            {/* {item?.saleType === "OWNER" ?
                                            <div className="tag-1 bg_default color-white mt-10">
                                                เจ้าของขายเอง
                                            </div>
                                            :
                                            null} */}
                                            {item.quick ? 
                                            <div className="tag-2 bg_redpink color-white mt-10">
                                                ขายด่วน
                                            </div>
                                            : null }
                                        </div>
                                        <CardImg 
                                        alt=""
                                        src={item?.images[0]?.path} 
                                        />
                                    </div>
                                    <CardBody>
                                        <div className="body-1">
                                            <CardTitle>
                                                {item?.title}
                                            </CardTitle>
                                            <CardText>
                                                {item?.description}
                                            </CardText>
                                        </div>
                                        <div className="body-2">
                                            <CardText>{intl.format(item?.totalPrice)}</CardText>
                                            {item?.pricePerWa ?
                                                <CardText style={{color: color}} className="color-default">{intl.format(item?.pricePerWa)}/ตร.ว.</CardText>
                                            :null}
                                        </div>
                                        <CardText>
                                            <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                            {item?.saleDistrict + ", " + item?.saleProvince}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            )
                        })}
                        <Card 
                        style={{cursor:'pointer', height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}} 
                        onClick={() => history.push(`/maps`)}
                        >
                            <CardBody >
                                <div 
                                // className="shadow"
                                style={{
                                    // height: 80,
                                    // width: 80,
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    // borderRadius: 50
                                }}
                                >
                                    <FontAwesomeIcon icon={faAnglesRight} style={{fontSize: 25}} />
                                    <br />
                                    ทั้งหมด
                                </div>
                            </CardBody>
                        </Card>
                    </Carousel>
                </div>
                {pageData?.annonce.length > 0 ?
                <div className="page-body-3">
                    <p className="title mb-10">
                        ประกาศรับซื้อ
                    </p>
                    <Carousel
                    responsive={responsive}
                    infinite={false}
                    autoPlay={false}
                    ssr={true}
                    shouldResetAutoplay={false}
                    containerClass="carousel-container mb-30"
                    itemClass="carousel-item"
                    >
                        {pageData?.annonce?.map((items, i) =>{
                            const date = new Date(items.create_At).toLocaleDateString('th',{day:'2-digit', month:'short', year:'2-digit'});
                            var SizeUnitLow;
                            switch(items.size.lowest.square){
                                case 'squarewa' : SizeUnitLow = 'ตร.ว.'; 
                                break;
                                case 'ngan' : SizeUnitLow = 'งาน';
                                break;
                                default : SizeUnitLow = 'ไร่';
                            }

                            var SizeUnitMax;
                            switch(items.size.maximum.square){
                                case 'squarewa' : SizeUnitMax = 'ตร.ว.'; 
                                break;
                                case 'ngan' : SizeUnitMax = 'งาน';
                                break;
                                default : SizeUnitMax = 'ไร่';
                            }
                            function choose(val){
                                switch(val){
                                    case 'build' : return 'สร้างบ้าน';
                                    case 'SmallHouse' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดเล็ก';
                                    case 'LargeResidential' : return 'พัฒนาโครงการที่อยู่อาศัยขนาดใหญ่';
                                    case 'BigBusiness' : return 'ธุรกิจขนาดใหญ่';
                                    case 'SME' : return 'ธุรกิจขนาดเล็ก SME';
                                    case 'Speculate' : return 'เก็งกำไร';
                                    case 'Farming' : return 'การเกษตร';
                                    default : return 'อื่นๆ';
                                }
                            }
                            return(
                                <Card key={i}>
                                    <CardBody>
                                        <div className="header mb-10">
                                            <img alt="userImg" src={pageData?.page?.logo ? pageData?.page?.logo : blank_img} className="user-img" />
                                            <p className="name">{items?.user?.name}</p>
                                            <p className="oversize" style={{color: color}}>ต้องการ</p>
                                            <div className="text-wrapper" style={{backgroundColor: color}}>
                                                <FontAwesomeIcon icon={faLocationDot} className="mr-10 icon" />
                                                <div className="label">
                                                    <p className="label-1">
                                                        จ.{items?.province}
                                                    </p>
                                                    <p className="label-2">
                                                        อ.{items?.district}
                                                    </p>
                                                </div>
                                            </div>
                                            <small className="date">
                                                {date}
                                            </small>
                                        </div>
                                        
                                        <div className="text-description">
                                            <div className="font-weight-500 color-default mb-10" style={{color: color}}>
                                                วัตถุประสงค์: 
                                                <p className="font-weight-300 ml-10 color-black" >{`${choose(items?.choice)}`}</p>
                                            </div>
                                            <div className="flex font-weight-500 color-default mb-10" style={{color: color}}>
                                                ขนาดเนื้อที่: <div className="font-weight-300 ml-10 color-black">{`${items?.size?.lowest?.count} ${SizeUnitLow} - ${items?.size?.maximum?.count} ${SizeUnitMax}`}</div>
                                            </div>
                                            <div className="flex font-weight-500 color-default " style={{color: color}}>
                                                ราคารวม: 
                                            </div>
                                            <div className="color-black mb-10">
                                                {`${intl.format(items?.price?.lowest)} - ${intl.format(items?.price?.maximum)}`}
                                            </div>
                                            <div className="flex font-weight-500 color-default" style={{color: color}}>
                                                รายละเอียด: 
                                            </div>
                                            <div className="text-detail color-black">
                                                {items?.description} 
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter
                                    style={{
                                        display:'flex', 
                                        justifyContent:'space-between',
                                        padding: 10
                                    }}
                                    >
                                        <Button 
                                        color="transparent"
                                        style={{color: '#909090'}}
                                        onClick={() => window.open('https://www.taladteedin.com/announce_view/'+ items._id)}
                                        >
                                            <FontAwesomeIcon icon={faCommentDots} style={{fontSize: 20}} /> {items?.comments?.length}
                                        </Button>
                                        <div style={{display: 'flex'}}>
                                            <Button 
                                            id={`present${i}`}
                                            color="round-default-sm"
                                            onClick={() => window.open(pageData?.page?.social?.lineUrl)}
                                            >
                                                <FontAwesomeIcon icon={faFileImport} style={{fontSize: 20}} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`present${i}`}
                                            >
                                                เสนอขาย
                                            </UncontrolledTooltip>

                                            <Button 
                                            id={`info${i}`}
                                            color="round-sky-sm"
                                            onClick={() => window.open('https://www.taladteedin.com/announce_view/'+ items._id)}
                                            >
                                                <FontAwesomeIcon icon={faEllipsisV} style={{fontSize: 20}} />
                                            </Button>
                                            <UncontrolledTooltip
                                            target={`info${i}`}
                                            >
                                                รายละเอียด
                                            </UncontrolledTooltip>
                                        </div>
                                    </CardFooter>
                                </Card>
                            )
                        })}
                    </Carousel>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Button
                        color="default"
                        style={{
                            backgroundColor: color,
                            color: 'white'
                        }}
                        >
                            ดูกระกาศทั้งหมด
                        </Button>
                    </div>
                </div>
                : null}
                <div className="page-property">
                    <Row className="row-cols-2 row-cols-md-4">
                        <Col className="mb-10">
                            <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                <label className="label-1" style={{color:'#000'}}>รวมประกาศขาย</label>
                                <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faBullhorn} />
                                </div>
                                <p className="text-number" style={{color: color}}>
                                    {
                                    pageData?.countLand + 
                                    pageData?.countHome + 
                                    pageData?.countCommercial + 
                                    pageData?.countHotel + 
                                    pageData?.countFactory + 
                                    pageData?.countMore
                                    }
                                </p>
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                <label className="label-1" style={{color:'#000'}}>ที่ดินทั้งหมด</label>
                                <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faMapLocationDot} />
                                </div>
                                <p className="text-number" style={{color: color}}>{pageData?.countLand}</p>
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                <label className="label-1" style={{color:'#000'}}>บ้านทั้งหมด</label>
                                <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faHouse} />
                                </div>
                                <p className="text-number" style={{color: color}}>{pageData?.countHome}</p>
                            </div>
                        </Col>
                        <Col className="mb-10">
                            <div className="property-item" style={{backgroundColor:'#F2F3F4'}}>
                                <label className="label-1" style={{color:'#000'}}>อสังหาฯอื่นๆ</label>
                                <label className="label-2" style={{color:'#000'}}>ทรัพย์</label>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faCity} />
                                </div>
                                <p className="text-number" style={{color: color}}>
                                    {
                                        pageData?.countCommercial + 
                                        pageData?.countHotel + 
                                        pageData?.countFactory + 
                                        pageData?.countMore
                                    }
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="page-body-4">
                    <div className="box-1">
                        <div className="subBox">
                            <div className="decorate-1"></div>
                            <div className="decorate-2"></div>
                            <img alt="" src={pageData?.page?.user?.profileImg ? pageData?.page?.user?.profileImg : pageData?.page?.logo} className="profile-img" />
                        </div>
                    </div>
                    <div className="box-2">
                        <div className="subBox">
                            <p style={{fontSize: '3em', fontWeight: 500}}>
                                {pageData?.page?.user?.name}
                            </p>
                            <h5 style={{marginBottom: 30}}>
                                นายหน้าอสังหาริมทรัพย์
                            </h5>
                            <p>
                                {pageData?.page?.detailProperty}
                            </p>
                        </div>
                        
                        <div className="subBox-2">
                            {pageData?.page?.user?.phoneNumber ? 
                            <Button 
                            // href={`tel:${pageData?.page?.user?.phoneNumber}`}
                            color="default"
                            className="mr-10 mb-10" 
                            style={{
                                backgroundColor: color
                            }}
                            onClick={() => window.location.href = `tel:${pageData?.page?.user?.phoneNumber}`}
                            >
                                <FontAwesomeIcon icon={faPhone} className="mr-10" />
                                {pageData?.page?.user?.phoneNumber}
                            </Button>
                            : null}
                            {pageData?.page?.social?.facebookUrl ? 
                            <Button
                            // href={`${pageData?.page?.social?.facebookUrl}`}
                            className="mr-10 mb-10" 
                            color="default"
                            style={{
                                backgroundColor: color
                            }}
                            onClick={() => window.open(pageData?.page?.social?.facebookUrl)}
                            >
                                <FontAwesomeIcon icon={faFacebookF} />
                            </Button>
                            : null}
                            {pageData?.page?.social?.lineUrl ? 
                            <Button 
                            // href={`${pageData?.page?.social?.lineUrl}`}
                            className="mr-10 mb-10" 
                            color="default"
                            style={{
                                backgroundColor: color
                            }}
                            onClick={() => window.open(pageData?.page?.social?.lineUrl)}
                            >
                                <FontAwesomeIcon icon={faLine} />
                            </Button>
                            : null}
                            {pageData?.page?.social?.tiktokUrl ? 
                            <Button 
                            // href={`${pageData?.page?.social?.tiktokUrl}`}
                            className="mr-10 mb-10" 
                            color="default"
                            style={{
                                backgroundColor: color
                            }}
                            onClick={() => window.open(pageData?.page?.social?.tiktokUrl)}
                            >
                                <FontAwesomeIcon icon={faTiktok} />
                            </Button>
                            : null}
                            {pageData?.page?.social?.youtubeUrl ? 
                            <Button 
                            // href={`${pageData?.page?.social?.youtubeUrl}`}
                            className="mr-10 mb-10" 
                            color="default"
                            style={{
                                backgroundColor: color
                            }}
                            onClick={() => window.open(pageData?.page?.social?.youtubeUrl)}
                            >
                                <FontAwesomeIcon icon={faYoutube} />
                            </Button>
                            : null}
                        </div>
                    </div>
                </div>
                {pageData?.page?.tiktokContent?.length > 0 ? 
                <div className="page-body-5">
                    <p className="title mb-10">
                        TikTok Content
                    </p>
                    <div className="tiktok-container">
                        {Array.from(pageData?.page?.tiktokContent).map((item, i) =>{
                            return(
                                <div className={`tiktok_item tiktok_item-${i+1}`} key={i}>
                                    <TikTok 
                                    url={`${item.url}`}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                : null}
                <div className="page-body-6">
                    <p style={{color: color}} className="title mb-10">
                        บทความน่าสนใจ
                        <p className="color-black">
                            อัพเดทข่าวสาร บทความที่ดิน วงการอสังหาริมทรัพย์
                        </p>
                    </p>
                    <div className="image-container">
                        {Array.from(pageData?.contentData).map((item, i) =>{
                            return(
                                <div 
                                className={`gallery__item gallery_item-${i+1}`}
                                onClick={() => window.open(`https://www.taladteedin.com/contentview/${item._id}`)}
                                >
                                    <img 
                                    src={item.coverPath} 
                                    className="gallery__img" 
                                    alt={`img ${i}`}
                                    />
                                    <div className="backdrop">
                                        <p className="text">
                                            {item.title}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Button
                        color="default"
                        style={{
                            backgroundColor: color,
                            color: 'white'
                        }}
                        onClick={() => window.open("https://www.taladteedin.com/content")}
                        >
                            ดูบทความทั้งหมด
                        </Button>
                    </div>
                </div>
            </div>
            {pageData?.page?.social?.lineUrl ? 
            <a href={pageData?.page?.social?.lineUrl} id="line-customer-chat" class="line-chat" target="_blank">
                <div 
                id="text-telling"
                class="text-telling" 
                >
                    ติดต่อสอบถาม 👋
                </div>
                <img alt="line-img" src="/LINE_APP_Android.png" style={{width: 50, height: 50, marginLeft: 10}} />
            </a>
            : null}
            
            </>
        :null
    )
}
export default MyPage