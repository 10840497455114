import Swal from "sweetalert2";

export const SuccessAlert = () =>{
    Swal.fire({
        icon: 'success',
        title: 'บันทึกเรียบร้อยแล้ว',
        showConfirmButton: false,
        timer: 1500
    })
}

export const ErrorAlert = ({text}) =>{
    Swal.fire({
        icon:'error',
        title: 'ERROR!',
        text: text,
        confirmButtonColor: '#DE1414'
    })
}
